import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { Observable, of, switchMap } from 'rxjs';

function hasCorrectPermissions(
  auth: AuthService,
  permission?: string,
): Observable<boolean> {
  if (!permission) return of(true);

  return auth.hasPermissionTo$(permission);
}

function redirectUser(
  loggedIn: boolean,
  hasPermissions: boolean,
): boolean | UrlTree {
  if (loggedIn) {
    if (hasPermissions) {
      return true;
    } else {
      // TODO: redirect use to specific page showing error?
      return false;
    }
    // TODO: Redirect user to last page before they were logged in?
    // TODO: Silent reauthentication?
  } else {
    return false;
  }
}

export const authGuard: CanActivateFn = (route, state) => {
  const auth = inject(AuthService);
  return auth.isAuthenticated$.pipe(
    switchMap((loggedIn) => {
      if (!loggedIn) {
        return auth.login();
      } else {
        return hasCorrectPermissions(auth, route.data['permission']).pipe(
          switchMap((hasPermissions) =>
            of(redirectUser(loggedIn, hasPermissions)),
          ),
        );
      }
    }),
  );
};
